/**
 * Handle slider controls and options
 */

import Swiper, { Navigation, Pagination } from "swiper";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const swiper = new Swiper(".swiper", {
  modules: [Navigation, Pagination],
  slidesPerView: 1,
  spaceBetween: "15%",
  autoHeight: true,
  loop: true,

  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const newsUpdateSwiper = new Swiper(".news-update-slider", {
  modules: [Navigation, Pagination],
  slidesPerView: 3,
  autoHeight: false,
  loop: false,

  pagination: {
    el: ".news-swiper-pagination",
    clickable: true,
  },

  breakpoints: {
    992: {
      slidesPerView: 3,
      spaceBetween: "4%",
    },

    768: {
      slidesPerView: 2,
      spaceBetween: "7%",
    },

    320: {
      slidesPerView: 1,
      spaceBetween: "10%",
    },
  },

  navigation: {
    nextEl: ".news-next",
    prevEl: ".news-prev",
  },
});
