import "./swiper";

import "./css/styles.css";
import "./css/swiper.css";
import '../node_modules/preloadar/dist/preloadar.min.css'; 

const preloadar = require("preloadar");

preloadar.run().auto("#preloadar", {
  animation: "rotate",
  onComplete: () => {
    document.getElementById('content-container').style.display = 'block';
  },
});
